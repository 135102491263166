window.addEventListener('load', () => {
    const getInTouchForm = document.getElementById('getInTouchForm');

    if (getInTouchForm) {
        getInTouchForm.addEventListener('submit', function (event) {
            event.preventDefault();
            const thisForm = this;

            thisForm.querySelector('button[type=submit]').setAttribute('disabled', 'disabled');
            thisForm.style.opacity = '0.4';

            const formData = new FormData(this);
            const formString = new URLSearchParams(formData).toString();

            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/', true);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhr.onreadystatechange = (event) => {
                if (event.target.readyState === XMLHttpRequest.DONE && event.target.status === 200) {
                    thisForm.style.display = 'none';
                    document.getElementById('getInTouchFormThanks').style.display = 'block';
                }
            }
            xhr.send(formString);
        });

    }
});