import axios from 'axios';

window.addEventListener('load', () => {
    const surveyForm = document.getElementById('surveyForm');

    if (surveyForm) {

        /**
         * When field changes make sure it doesnt have is-invalid class for re-validation.
         */
        document.querySelectorAll('.form-control').forEach((element) => {
            ['change', 'keyup'].forEach((event) => {
                element.addEventListener(event, (field) => {
                    field.target.classList.remove('is-invalid');
                });
            });
        });

        /**
         * Ensure only numbers are accepted in the number and tel fields
         */
        surveyForm.querySelectorAll('[type="tel"], [type="number"]').forEach((element) => {
            element.addEventListener('input', (event) => {
                event.target.value = event.target.value.replace(/[^0-9]/g, "");
            })
        })

        surveyForm.addEventListener('submit', function (event) {
            event.preventDefault();
            const thisForm = this;

            thisForm.querySelector('button[type=submit]').setAttribute('disabled', 'disabled');
            thisForm.style.opacity = '0.4';

            //Reset form errors
            document.querySelectorAll('.form-control').forEach((element) => {
                element.classList.remove('is-invalid');
            });

            document.querySelectorAll('.form-control + .invalid-feedback').forEach((element) => {
                element.innerHTML = '';
            });

            const formData = new FormData(this);

            axios.post('/', formData).then((response) => {
                surveyForm.classList.add('d-none');
                document.getElementById('surveyFormThanks').classList.remove('d-none');
            }).catch((error) => {
                console.log(error.response.data.errors);

                if(typeof error.response.data.errors !== 'undefined') {
                    console.log('There is validation errors');
                    const validationErrors = Object.entries(error.response.data.errors);

                    validationErrors.forEach((validationError) => {
                        let fieldName = validationError[0];
                        const errors = validationError[1];

                        if(fieldName.includes('message.')) {
                            fieldName = fieldName.replace('message.','message[') + ']';
                        }

                        console.log('FieldName: ' + fieldName);

                        const domElement = document.querySelector('[name="' + fieldName + '"]');
                        const domFeedback = document.querySelector('[name="' + fieldName + '"] + div.invalid-feedback');

                        if(domElement && domFeedback) {
                            console.log('Element: ', domElement);
                            console.log('Feedback: ', domFeedback);

                            if(errors.length) {
                                const errorElement = document.createElement("span");
                                const errorContent = document.createTextNode(errors[0]);
                                errorElement.appendChild(errorContent);
                                domFeedback.appendChild(errorElement);
                                domElement.classList.add('is-invalid');
                            }
                        }
                    })
                }

                thisForm.querySelector('button[type=submit]').removeAttribute('disabled');
                thisForm.style.opacity = '1';
            });
        });

    }
});