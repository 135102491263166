window.addEventListener('load', () => {

    const valueMyPropertyForm = document.getElementById('valueMyPropertyForm');
    const valueMyPropertyAddressField = document.getElementById('valueMyPropertyAddressField');
    const valueMyPropertyFormattedField = document.getElementById('valueMyPropertyFormattedAddress');

    if(valueMyPropertyForm && valueMyPropertyAddressField) {

        const searchBox = new google.maps.places.Autocomplete(valueMyPropertyAddressField, {
            componentRestrictions: {country: 'uk'}
        });

        searchBox.addListener('place_changed', () => {
            valueMyPropertyFormattedField.value = searchBox.getPlace().formatted_address;
        });

        valueMyPropertyForm.addEventListener('submit', (event) => {
            event.preventDefault();

            console.log(searchBox.getPlace());

            if(typeof searchBox.getPlace() === 'undefined') {
                alert('Please make sure you have selected your address correctly...');
                return false;
            }

            const thisForm = event.target;

            thisForm.querySelector('button[type=submit]').setAttribute('disabled', 'disabled');
            thisForm.style.opacity = '0.4';

            const formData = new FormData(event.target);
            const formString = new URLSearchParams(formData).toString();

            const xhr = new XMLHttpRequest();
            xhr.open('POST', '/', true);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhr.onreadystatechange = (event) => {
                if (event.target.readyState === XMLHttpRequest.DONE && event.target.status === 200) {
                    thisForm.classList.add('d-none');
                    document.getElementById('valueMyPropertyFormThanks').classList.remove('d-none');
                }
            }
            xhr.send(formString);
        });
    }

});