import A11yDialog from 'a11y-dialog';

window.addEventListener('load', () => {
    const propertyAlertsDialogElement = document.getElementById('propertyAlertsModal');

    if (propertyAlertsDialogElement) {
        const propertyAlertsDialog = new A11yDialog(document.getElementById('propertyAlertsModal'));

        ['click', 'touch'].forEach(function (eventHandler) {
            document.querySelectorAll('[data-action="propertyAlertsDialog"]').forEach(function (propertyAlerts) {
                propertyAlerts.addEventListener(eventHandler, () => {
                    propertyAlertsDialog.show();
                });
            });
        });

        propertyAlertsDialog.on('show', () => {
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        });

        propertyAlertsDialog.on('hide', () => {
            document.getElementsByTagName('body')[0].style.overflow = 'visible';
        });
    }
});