import A11yDialog from 'a11y-dialog';

window.addEventListener('load', () => {

    const valuePropertyDialogElement = document.getElementById('valuePropertyModal');

    if (valuePropertyDialogElement) {
        const valuePropertyDialog = new A11yDialog(document.getElementById('valuePropertyModal'));

        ['click', 'touch'].forEach(function (eventHandler) {
            document.querySelectorAll('[data-action="valueProperty"]').forEach(function (valueProperty) {
                valueProperty.addEventListener(eventHandler, () => {
                    valuePropertyDialog.show();
                });
            });

            document.querySelector('[data-valuation="instant"]').addEventListener(eventHandler, () => {
                valuationOptions.style.display = 'none';
                instantValuation.style.display = 'block';
                inPersonValuation.style.display = 'none';
            });
            document.querySelector('[data-valuation="in-person"]').addEventListener(eventHandler, () => {
                valuationOptions.style.display = 'none';
                instantValuation.style.display = 'none';
                inPersonValuation.style.display = 'block';
            });
        });

        const valuationOptions = document.getElementById('valuation-options');
        const instantValuation = document.getElementById('instant-valuation');
        const inPersonValuation = document.getElementById('in-person-valuation');

        valuePropertyDialog.on('hide', () => {
            valuationOptions.style.display = 'block';
            instantValuation.style.display = 'none';
            inPersonValuation.style.display = 'none';
            document.getElementsByTagName('body')[0].style.overflow = 'visible';
        });

        valuePropertyDialog.on('show', () => {
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        });
    }

});