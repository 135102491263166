import Glider from '@glidejs/glide';
window.Glider = Glider;

// window.A11yDialog = require('a11y-dialog');

import { tns } from 'tiny-slider/src/tiny-slider';
window.tns = tns;

window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');

require('bootstrap');
require('bootstrap-select');

require('@fancyapps/fancybox');