window.addEventListener('load', function () {

    require('goodshare.js');

    const clipboardItem = document.querySelector('[data-action="copy"]');

    if(clipboardItem !== null) {
        window._goodshare.reNewAllInstance();

        ['click', 'touch'].forEach(function (eventListener) {
            clipboardItem.addEventListener(eventListener, handleCopy);
        });

        function handleCopy() {
            copyTextToClipboard(window.location.protocol+'//'+window.location.host+window.location.pathname);
        }

        function fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                if (successful) {
                    alert('The link has been copied to your clipboard');
                }
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }

        function copyTextToClipboard(text) {
            if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(function () {
                alert('The link has been copied to your clipboard');
            }, function (err) {
                console.error('Async: Could not copy text: ', err);
            });
        }
    }

});