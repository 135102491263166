require('./bootstrap');
import "core-js";

import './components/share-card';
import './components/meet-the-team-dialog';
import './components/property-alerts-modal';
import './components/value-property-modals';
import './components/get-in-touch-form';
import './components/value-my-property-form';
import './components/value-my-property-section-form';
import './components/survey-form';

import './pages/home';