import axios from 'axios';
import A11yDialog from 'a11y-dialog';

window.addEventListener('load', () => {

    const teamMemberModal = document.getElementById('teamMemberModal');

    if(teamMemberModal) {
        const teamMemberDialog = new A11yDialog(teamMemberModal);

        teamMemberDialog.on('show', (element, event) => {
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        });

        teamMemberDialog.on('hide', (element, event) => {
            document.getElementsByTagName('body')[0].style.overflow = 'visible';
        });

        const profileButtons = document.querySelectorAll('button[data-profile]');
        const profileModal = document.querySelector('#teamMemberModal');

        profileButtons.forEach(profileButton => {
            ['click', 'touch'].forEach(eventHandler => {
                profileButton.addEventListener(eventHandler, (event) => {
                    const profileUrl = event.target.getAttribute('data-profile');
                    console.log('Load profile: ' + profileUrl);

                    axios.get(profileUrl).then(response => {
                        document.querySelector('#teamMemberModalContent').innerHTML = response.data;

                        teamMemberDialog.show();
                    }).catch(error => {
                        console.log(error);
                    });
                });
            });
        });
    }
});