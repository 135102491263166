window.addEventListener('load', function () {

    const searchForm = document.getElementById('video_search_form');

    if(searchForm) {
        const searchFormAction = window.location.origin + '/property-search/';
        const searchInput = document.getElementById('video_banner_search');

        const searchBox = new google.maps.places.Autocomplete(searchInput, {
            componentRestrictions: {country: 'uk'}
        });

        searchForm.addEventListener('submit', function(event){
            event.preventDefault();

            return !!searchBox.getPlace();
        });

        searchBox.addListener('place_changed', () => {
            searchForm.submit();
        });

        let searchToggles = document.querySelectorAll('[data-action="search-toggle"] label');
        let searchButton = document.querySelector('.video-banner .search button.btn-search-button');
        let toggleClassPrefix = 'btn-group-toggle-';

        searchToggles.forEach(function (toggle) {
            ['click', 'touch'].forEach(function (eventBinding) {
                toggle.addEventListener(eventBinding, handleToggle);
            });
        });

        function handleToggle() {
            if(this.classList.contains('active') === false) {
                searchToggles.forEach(function(searchToggle) {
                    searchToggle.classList.remove('active');
                    searchToggle.parentElement.classList.remove(toggleClassPrefix + searchToggle.getAttribute('data-toggle-colour'));
                    searchButton.classList.remove('btn-' + searchToggle.getAttribute('data-toggle-colour'));
                });

                this.classList.add('active');
                this.parentElement.classList.add(toggleClassPrefix + this.getAttribute('data-toggle-colour'));

                searchForm.setAttribute('action', searchFormAction + this.querySelector('input[type="radio"]').value);

                searchButton.classList.add('btn-' + this.getAttribute('data-toggle-colour'));
            }
        }
    }

});